*,
*::before,
*::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
}

body {
    padding: 0%;
    margin: 0;
    font-family: sans-serif;
  }

