@use '../../styles/globals';

.key {
    width: 50px;
    height: 55px;
    border-radius: 4px;
    display: grid;
    place-items: center;
    font-size: 13px;
    font-weight: 700;
    background-color: grey;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    cursor: pointer;

    &__backspace-icon {
        height: 32px;
    }
  }
  
  #big {
    width: 100px;
  }
  #disabled {
    background-color: #3a393c;
  }