@use '../../styles/globals';

.keyboard {
    width: 100%;
    max-width: 500px;

    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    padding: 12px;

    &__row {
        flex: 33%;
        display: flex;
        flex-direction: row;
        display: flex;
        justify-content: center;
        gap: 6px;
        width: 100%;
        &--middle {
            width: 91%;
        }
    }
}