@use '../../styles/globals';
@use '../../styles/mixins' as *;
@use '../../styles/variables' as *;

.gameOver {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &__content {
    background-color: rgba(30, 30, 30, 1);
    max-width: 500px;
    color: white;
    width: 100%;
    margin: 20px;
    padding: 30px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }

  &__subhead {
    @include subhead;

  }

  &__stats {
    font-size: 16px;
    text-align: center;
  }

  
}

.gameOver__content {}

.gameOver__subhead {}