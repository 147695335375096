@use './variables' as *;

// SCREENSIZING MIXINS

@mixin tablet {
    @media screen and (min-width: $tablet-breakpoint) {
        @content;
    }
}

@mixin desktop {
    @media screen and (min-width: $desktop-breakpoint) {
        @content;
    }
}

@mixin full-width-buttons() {
    display: block;
    width: 100%;
    height: 48px;
    border: none;
    border-radius: 18px;
    font-size: 16px;
    font-weight: 700;
    color: white;
    max-width: 300px;
}

@mixin subhead {
    font-weight: 700;
    font-size: 24px;
    text-align: center;
}

@mixin wordle-input {
    width: 100%;
        max-width: 300px;
        border-radius: 18px;
        height: 48px;
        text-align: center;
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
        background-color: rgba(255, 255, 255, 0.1);
        border: none;
        color: white;
}