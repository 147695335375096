@use '../../styles/globals';

nav {
    height: 60px;
    width: 100%;
    margin: 0;
    border-bottom: 1px solid grey;
    display: grid;
    place-items: center;
  }

  nav h1 {
    margin: 0;
    color: white;
    font-size: 24px;
  }