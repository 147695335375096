@use './styles/globals';


.app {
  background-color: #121212;
  width: 100%;
  height: 100%;
}

.game {
  width: 100%;
  height: calc(100vh - 59px);
  display: flex;
  flex-direction: column;
  justify-content:space-between;
  align-items:center;
  
}








