.board {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 5px;
  }